.no-info {
    color: grey;
    font-size: 12px;
    font-weight: initial;
}

.rc-slider-handle {
    box-shadow: none !important;
}

.b-items__cars-one-info-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.listing-info {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 50px;
}